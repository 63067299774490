















































































import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import CartItems from '@/components/desktop-pos/cart-items.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Printer from '@/libs/imin-printer.esm.browser.min'
import html2canvas from 'html2canvas'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
    components: {
        CustomerInfo,
        CartItems,
        PriceSummary,
        MemberCard,
        Receipt,
        DesktopPosNavbar
    },
    computed: {
        ...mapState({
            selectBranch: (state: any) => state.Store.selectBranch,
            selectPos: (state: any) => state.Store.selectPos
        }),
        ...mapGetters({
            net: 'Cart/net',
            bill: 'Cart/bill',
            cashAmount: 'Cart/cashAmount',
            payment: 'Cart/payment'
        })
    },
    methods: {
        ...mapActions({
            clearCart: 'Cart/clearCart',
            printReceipt: 'Print/printReceipt'
        })
    }
})
export default class OrderSuccess extends Vue {
    net!: any

    receipt: any = null

    selectBranch!: any

    selectPos!: any

    cashAmount!: any

    base64Url = ''

    bill!: any

    status = ''

    showPriceFormat!: any

    clearCart!: any

    payment!: any

    printReceipt!: any

    printLoading = true

    printer!: any

    async mounted (): Promise<any> {
        this.receipt = await this.printReceipt({ status: 'no_print', ip: 'no print', localPrint: true })
        this.printLoading = true
        this.status = 'กำลังเตรียมการปรินท์'
        this.printer = new Printer()
        setTimeout(async () => {
          await this.openCashBox()
          this.localPrint(true)
        }, 1000)
    }

    async openCashBox (): Promise<boolean> {
      const connected = await this.printer.connect()
      if (connected) {
        this.printer.openCashBox()
      }
      return true
    }

    async localPrint (isTwice = false): Promise<void> {
      try {
          const connected = await this.printer.connect()
          if (connected) {
              this.status = 'กำลังเชื่อมต่อกับเครื่องปรินท์'
              const el: HTMLElement | any = this.$refs.receiptTemplate
              const elementToConvert = await html2canvas(el.$el, {
                scale: 1
              })
              // const fixedWidth = 576
              // ----------------------------- resize ------------------------------- //
              // const aspectRatio = elementToConvert.height / elementToConvert.width
              // const fixedHeight = fixedWidth * aspectRatio
              // const resizedCanvas = document.createElement('canvas')
              // resizedCanvas.width = fixedWidth
              // resizedCanvas.height = fixedHeight
              // const ctx = resizedCanvas.getContext('2d')
              // if (ctx) {
              //   ctx.drawImage(elementToConvert, 0, 0, resizedCanvas.width, resizedCanvas.height)
              // }
              // ----------------------------- resize ------------------------------- //

              if (elementToConvert) {
                // this.base64Url = resizedCanvas.toDataURL('image/png')
                this.base64Url = elementToConvert.toDataURL('image/png')
                this.status = 'กำลังเตรียมข้อมูลเพื่อปรินท์'
              } else {
                this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
              }
              this.printer.initPrinter()
              if (this.base64Url) {
                  this.status = 'กำลังทำการปรินท์'
                  if (isTwice) {
                    await this.printer.printSingleBitmap(this.base64Url, 53)
                    await this.printer.printAndFeedPaper(100)
                    await this.printer.partialCut()
                    await this.printer.printSingleBitmap(this.base64Url, 53)
                    await this.printer.printAndFeedPaper(100)
                    await this.printer.partialCut()
                  } else {
                    await this.printer.printSingleBitmap(this.base64Url, 53)
                    await this.printer.printAndFeedPaper(100)
                    await this.printer.partialCut()
                  }
                  setTimeout(() => {
                    this.status = 'ปรินท์เสร็จสิ้น'
                  }, 5000)
              } else {
                this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
              }
          } else {
              this.status = 'ไม่สามารถเชื่อมต่อกับเครื่องปรินท์ได้'
          }
      } catch (error: any) {
          this.status = 'เกิดข้อผิดพลาดกรุณารีเฟรชหรือกดพิมพ์ใบเสร็จอีกครั้ง'
      }
      this.printLoading = false
    }

    closedOrder (): void {
      this.clearCart()
      this.$router.push({ name: 'DesktopCart' })
    }
}
